<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="PACKAGES"
          :breadcrumb="[
            { label: 'Retailer packages ' },
            { label: 'packages List' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row mb-1" style="margin-top: -45px">
          <div
            class="col-xl-4 col-lg-4 col-md-4 col-12"
            style="padding: 5px 5px 5px 5px"
            v-for="(pkg, index) in packages"
            :key="index"
          >
            <div class="card custom-card" style="height: 100%">
              <div class="card-body">
                <img
                  class="border-row"
                  src="/assets/image/fab2.png"
                  style="width: 25px"
                  alt=""
                />
                <b class="text" style="font-size: 12pt"
                  >&nbsp;&nbsp;&nbsp;{{ pkg.package }}</b
                >
                <h1 class="text-danger border-row">
                  Rs. <b>{{ pkg.onBoardingCharge }}</b>
                </h1>

                <div
                  class="row border-row"
                  style="
                    border-radius: 10px;
                    background-color: #f2f2f2;
                    line-height: 12px;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    padding: 10px 10px 10px 10px;
                  "
                >
                  <b style="color: #f21300"
                    ><small
                      >Earn upto Rs.15000 per month and enjoy higher comission.</small
                    ></b
                  >
                </div>

                <div class="pb-1">
                  <b class="text" style="font-size: 11pt">
                    {{ pkg.remark }}
                  </b>
                </div>
                <div class="row border-row">
                  <div class="col-md-12 col-sm-12 col-xl-12">
                    <ul class="navbar-nav mr-auto">
                      <li
                        class="nav-item"
                        style="margin-top: 2px"
                        v-for="(pkg_service, index) in pkg.package_services"
                        :key="index"
                      >
                        <div class="d-flex">
                          <div>
                            <font-awesome-icon
                              icon="check"
                              class="bg-danger text-white"
                              style="padding: 2px; border-radius: 4px"
                            />
                          </div>
                          &nbsp;&nbsp;
                          <div>
                            <b class="text" style="margin-left: 0px">{{
                              pkg_service.service.name
                            }}</b>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="row">
                  <!-- v-if="retailerpackage.length > 0" -->
                  <div class="col-md-12 col-xl-12 col-sm-12">
                    <br />

                    <button
                      v-if="checkRetailerPackage(pkg.id)"
                      @click="allreddyActivated()"
                      type="button"
                      class="btn btn-sm border-row"
                      style="
                        box-shadow: 0px 0px 5px 0px gray;
                        border-radius: 20px;
                        background-color: #f5f5f5;
                        position: absolute;
                        bottom: 20px;
                        left: 230px;
                      "
                    >
                      Activated
                    </button>
                    <button
                      v-else
                      @click="activatePackage(pkg)"
                      type="button"
                      class="btn btn-sm border-row text-white"
                      style="
                        box-shadow: 0px 0px 5px 0px gray;
                        border-radius: 20px;
                        background-color: #00364f;
                      "
                    >
                      Activate @ Rs.{{ pkg.onBoardingCharge }}/-
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="d-block d-xl-none d-lg-none d-md-none mt-5">dfgdfgd</div> -->
          </div>
        </div>

        <!-- end of table area -->
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/retailer/comman/Banner.vue";
import Spinner from "../../../components/retailer/comman/Spinner.vue";
import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "Packages",
  retailer_id: "",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      loading: false,
      packages: [],
      retailer_id: "",
      services: [],
      packageservice: [],
      retailerpackage: [],
      form: new Form({}),
    };
  },
  methods: {
    checkRetailerPackage(pkg_id) {
      var pkg = this.retailerpackage.find((p) => p.package_id == pkg_id);

      console.log(pkg);
      if (pkg) {
        return true;
      } else {
        return false;
      }
    },
    allreddyActivated() {
      alert("This Package Already Activated");
    },
    activatePackage(pkg) {
      // console.log(pkg)
      this.loading = true;
      this.$axios
        .post(
          `retailer/retailerpackage`,
          { package_id: pkg.id },
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          res.data;

          // console.log(res.data.data)
          // this.$swal.fire({
          //   position: "top-end",
          //   icon: "success",
          //   title: "The Package Activated successfully.",
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
          toast.success("Package Activated successfully.", {
                autoClose: 1000,
              });
          this.loadPackages();
        })
        .finally(() => (this.loading = false));
    },

    loadPackages() {
      this.loading = true;
      this.$axios
        .get("retailer/packageservice", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.packageservice = res.data.data.data;
        });
      // this.retailer_id = JSON.parse(localStorage.getItem('userData')).id
      this.$axios
        .get("retailer/package", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.packages = res.data.data.data;

          // console.log(res.data.data.data)
        });
      this.$axios
        .get(`retailer/retailerpackage`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.retailerpackage = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadPackages();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

/* .package-card {
  box-shadow: 0px 0px 5px 0px gray;
  margin-left: 8px;
  border-radius: 10px;
  position: relative;
  height: 100%;
  width: 96%;
} */

.border-row {
  margin-left: -8px;
  margin-right: -8px;
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
